/**
 * File mirror-fields.js.
 * 
 * Keep values of label fields syncronized with it's related input fields.
 */
(function( $ ){

  'use strict';

  //
  // VARIABLES
  //
  
  var _originalFieldIds = [],
      _mirrorAttribute = 'data-mirror',
      _mirrorSelector = '[data-mirror]',
      _mirrorFieldsSelector = '[data-mirror="##ID##"]',
      _hasJQuery = ( $ != null );


  //
  // METHODS
  //

  var mirrorFieldValue = function( field ) {
    var mirrorFields = document.querySelectorAll( _mirrorFieldsSelector.replace( '##ID##', field.id ) );

    for (var i = mirrorFields.length - 1; i >= 0; i--) {
      mirrorFields[i].textContent = field.value;
    }
  };



  /**
   * Get ids of fields to watch for changes.
   */
  var initMirrorFields = function() {
    var mirrorFields = document.querySelectorAll( _mirrorSelector );
    for (var i = mirrorFields.length - 1; i >= 0; i--) {
      _originalFieldIds.push( mirrorFields[i].getAttribute( _mirrorAttribute ) );
    }
  };



  /**
   * Initialize select2 fields to watch for changes.
   */
  var initSelect2Fields = function() {
    // Only run if jQuery is loaded
    if ( _hasJQuery ) {
      for (var i = _originalFieldIds.length - 1; i >= 0; i--) {
        // Is field select2?
        if ( $( '#'+_originalFieldIds[i] ).data( 'select2' ) ) {
          // Add jQuery event listener
          $( '#'+_originalFieldIds[i] ).on( 'select2:select', handleChange );
        }
      }
    }
  };



  /**
   * Handle captured `change` event and route to the appropriate function.
   */
  var handleChange = function( e ) {
    if ( _originalFieldIds && _originalFieldIds.includes( e.target.id ) ) {
      mirrorFieldValue( e.target );
    }
  };



  /**
   * Initialize component and set related handlers
   */
  function init() {
    // Initialize mirror fields array
    initMirrorFields();
    initSelect2Fields();

    // Set event handlers
    document.addEventListener( 'change', handleChange, true );
    document.addEventListener( 'keyup', handleChange, true );
  };



  // Run initialize on pageload
  window.addEventListener( 'load', init );

})( window.jQuery );
